import { WithStatusCU as imported747_WithStatusCU, WithStatusU as imported373_WithStatusU, WithStatusCU as imported746_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import * as O from "fp-ts/lib/Option";
import { LocalDateTime } from "@js-joda/core";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BondOfferingC as imported30_BondOfferingC, BondOffering as imported30_BondOffering, bondOfferingC as imported30_bondOfferingC } from "./bondOfferingBase";
import { RfpC as imported75_RfpC, Rfp as imported75_Rfp, rfpC as imported72_rfpC } from "./rfpBase";

export type ContentNoteC = t.TypeC<{
  content: t.StringC
}>;
export type ContentNote = {
  content: string
};
export const contentNoteC: ContentNoteC = t.type({
  content: t.string
}) satisfies t.Type<ContentNote, unknown>;


export type OfferingContentNotePostC = t.TypeC<{
  content: t.StringC,
  offeringId: t.NumberC,
  id: OptionFromNullableC<t.NumberC>
}>;
export type OfferingContentNotePost = {
  content: string,
  offeringId: number,
  id: O.Option<number>
};
export const offeringContentNotePostC: OfferingContentNotePostC = t.type({
  content: t.string,
  offeringId: t.number,
  id: optionFromNullable(t.number)
}) satisfies t.Type<OfferingContentNotePost, unknown>;


export type RfpContentNotePostC = t.TypeC<{
  content: t.StringC,
  rfpId: t.NumberC,
  id: OptionFromNullableC<t.NumberC>
}>;
export type RfpContentNotePost = {
  content: string,
  rfpId: number,
  id: O.Option<number>
};
export const rfpContentNotePostC: RfpContentNotePostC = t.type({
  content: t.string,
  rfpId: t.number,
  id: optionFromNullable(t.number)
}) satisfies t.Type<RfpContentNotePost, unknown>;


export type ContentNoteForContactSubmissionPostC = t.TypeC<{
  content: t.StringC,
  contactSubmissionId: t.NumberC
}>;
export type ContentNoteForContactSubmissionPost = {
  content: string,
  contactSubmissionId: number
};
export const contentNoteForContactSubmissionPostC: ContentNoteForContactSubmissionPostC = t.type({
  content: t.string,
  contactSubmissionId: t.number
}) satisfies t.Type<ContentNoteForContactSubmissionPost, unknown>;


export type ActiveDealInfoRequestCountC<A1 extends t.Mixed> = t.TypeC<{
  deal: imported747_WithStatusCU<A1>,
  count: t.NumberC,
  mostRecent: LocalDateTimeFromIsoStringC
}>;
export type ActiveDealInfoRequestCount<A1> = {
  deal: imported373_WithStatusU<A1>,
  count: number,
  mostRecent: LocalDateTime
};
export const activeDealInfoRequestCountC = <A1 extends t.Mixed>(A1: A1): ActiveDealInfoRequestCountC<A1> => t.type({
  deal: imported746_WithStatusCU(A1),
  count: t.number,
  mostRecent: LocalDateTimeFromIsoStringC
}) satisfies t.Type<ActiveDealInfoRequestCount<t.TypeOf<A1>>, unknown>;


export type ActiveDealsInfoRequestCountC = t.TypeC<{
  offerings: t.ReadonlyArrayC<ActiveDealInfoRequestCountC<imported30_BondOfferingC>>,
  rfps: t.ReadonlyArrayC<ActiveDealInfoRequestCountC<imported75_RfpC>>
}>;
export type ActiveDealsInfoRequestCount = {
  offerings: ReadonlyArray<ActiveDealInfoRequestCount<imported30_BondOffering>>,
  rfps: ReadonlyArray<ActiveDealInfoRequestCount<imported75_Rfp>>
};
export const activeDealsInfoRequestCountC: ActiveDealsInfoRequestCountC = t.type({
  offerings: t.readonlyArray(activeDealInfoRequestCountC(imported30_bondOfferingC)),
  rfps: t.readonlyArray(activeDealInfoRequestCountC(imported72_rfpC))
}) satisfies t.Type<ActiveDealsInfoRequestCount, unknown>;


