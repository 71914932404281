import { WithStatusCU as imported750_WithStatusCU, WithStatusCU as imported749_WithStatusCU, WithStatusCU as imported752_WithStatusCU, WithStatusCU as imported751_WithStatusCU, WithStatusCU as imported753_WithStatusCU, WithStatusU as imported374_WithStatusU, WithStatusCU as imported748_WithStatusCU, WithStatusU as imported376_WithStatusU, WithStatusU as imported375_WithStatusU } from "./threadThrough";
import * as t from "io-ts";
import { MediaUploadResponseC as imported31_MediaUploadResponseC, Media as imported81_Media, mediaUploadResponseC as imported31_mediaUploadResponseC, mediaC as imported77_mediaC, MediaUploadResponse as imported31_MediaUploadResponse, MediaC as imported81_MediaC } from "./media";
import * as O from "fp-ts/lib/Option";
import { BigNumberC, ReadonlyBig, bigNumberC } from "../../Big/bigNumberC";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { dataAndIdC as imported11_dataAndIdC, DataAndIdC as imported11_DataAndIdC, DataAndId as imported10_DataAndId, dataAndIdC as imported10_dataAndIdC, DataAndId as imported11_DataAndId, DataAndIdC as imported10_DataAndIdC } from "./relatedContent";

export type BidSubmissionC = t.TypeC<{
  rfpId: t.NumberC,
  userId: t.NumberC,
  bidSubmissionTemplateId: t.NumberC,
  firstName: t.StringC,
  lastName: t.StringC,
  organization: t.StringC,
  email: t.StringC,
  interestRate: OptionFromNullableC<BigNumberC>,
  fees: OptionFromNullableC<BigNumberC>,
  additionalComments: OptionFromNullableC<t.StringC>
}>;
export type BidSubmission = {
  rfpId: number,
  userId: number,
  bidSubmissionTemplateId: number,
  firstName: string,
  lastName: string,
  organization: string,
  email: string,
  interestRate: O.Option<ReadonlyBig>,
  fees: O.Option<ReadonlyBig>,
  additionalComments: O.Option<string>
};
export const bidSubmissionC: BidSubmissionC = t.type({
  rfpId: t.number,
  userId: t.number,
  bidSubmissionTemplateId: t.number,
  firstName: t.string,
  lastName: t.string,
  organization: t.string,
  email: t.string,
  interestRate: optionFromNullable(bigNumberC),
  fees: optionFromNullable(bigNumberC),
  additionalComments: optionFromNullable(t.string)
}) satisfies t.Type<BidSubmission, unknown>;


export type BidSubmissionAnswerC = t.TypeC<{
  bidSubmissionId: t.NumberC,
  bidSubmissionTemplateQuestionId: t.NumberC,
  yesOrNo: OptionFromNullableC<t.BooleanC>,
  text: OptionFromNullableC<t.StringC>
}>;
export type BidSubmissionAnswer = {
  bidSubmissionId: number,
  bidSubmissionTemplateQuestionId: number,
  yesOrNo: O.Option<boolean>,
  text: O.Option<string>
};
export const bidSubmissionAnswerC: BidSubmissionAnswerC = t.type({
  bidSubmissionId: t.number,
  bidSubmissionTemplateQuestionId: t.number,
  yesOrNo: optionFromNullable(t.boolean),
  text: optionFromNullable(t.string)
}) satisfies t.Type<BidSubmissionAnswer, unknown>;


export type BidSubmissionDocumentC = t.TypeC<{
  bidSubmissionId: t.NumberC,
  bidSubmissionTemplateDocumentId: t.NumberC,
  mediaId: OptionFromNullableC<t.NumberC>
}>;
export type BidSubmissionDocument = {
  bidSubmissionId: number,
  bidSubmissionTemplateDocumentId: number,
  mediaId: O.Option<number>
};
export const bidSubmissionDocumentC: BidSubmissionDocumentC = t.type({
  bidSubmissionId: t.number,
  bidSubmissionTemplateDocumentId: t.number,
  mediaId: optionFromNullable(t.number)
}) satisfies t.Type<BidSubmissionDocument, unknown>;


export type BidSubmissionDocumentDataC = t.TypeC<{
  bidSubmissionDocument: BidSubmissionDocumentC,
  media: OptionFromNullableC<imported753_WithStatusCU<imported81_MediaC>>
}>;
export type BidSubmissionDocumentData = {
  bidSubmissionDocument: BidSubmissionDocument,
  media: O.Option<imported376_WithStatusU<imported81_Media>>
};
export const bidSubmissionDocumentDataC: BidSubmissionDocumentDataC = t.type({
  bidSubmissionDocument: bidSubmissionDocumentC,
  media: optionFromNullable(imported752_WithStatusCU(imported77_mediaC))
}) satisfies t.Type<BidSubmissionDocumentData, unknown>;


export type BidSubmissionDataC = t.TypeC<{
  bidSubmission: BidSubmissionC,
  answers: t.ReadonlyArrayC<imported750_WithStatusCU<BidSubmissionAnswerC>>,
  documents: t.ReadonlyArrayC<imported751_WithStatusCU<BidSubmissionDocumentDataC>>
}>;
export type BidSubmissionData = {
  bidSubmission: BidSubmission,
  answers: ReadonlyArray<imported374_WithStatusU<BidSubmissionAnswer>>,
  documents: ReadonlyArray<imported375_WithStatusU<BidSubmissionDocumentData>>
};
export const bidSubmissionDataC: BidSubmissionDataC = t.type({
  bidSubmission: bidSubmissionC,
  answers: t.readonlyArray(imported748_WithStatusCU(bidSubmissionAnswerC)),
  documents: t.readonlyArray(imported749_WithStatusCU(bidSubmissionDocumentDataC))
}) satisfies t.Type<BidSubmissionData, unknown>;


export type BidSubmissionDocumentPostC = t.TypeC<{
  media: OptionFromNullableC<imported31_MediaUploadResponseC>,
  bidSubmissionTemplateDocumentId: t.NumberC
}>;
export type BidSubmissionDocumentPost = {
  media: O.Option<imported31_MediaUploadResponse>,
  bidSubmissionTemplateDocumentId: number
};
export const bidSubmissionDocumentPostC: BidSubmissionDocumentPostC = t.type({
  media: optionFromNullable(imported31_mediaUploadResponseC),
  bidSubmissionTemplateDocumentId: t.number
}) satisfies t.Type<BidSubmissionDocumentPost, unknown>;


export type BidSubmissionAnswerPostC = t.TypeC<{
  bidSubmissionTemplateQuestionId: t.NumberC,
  yesOrNo: OptionFromNullableC<t.BooleanC>,
  text: OptionFromNullableC<t.StringC>
}>;
export type BidSubmissionAnswerPost = {
  bidSubmissionTemplateQuestionId: number,
  yesOrNo: O.Option<boolean>,
  text: O.Option<string>
};
export const bidSubmissionAnswerPostC: BidSubmissionAnswerPostC = t.type({
  bidSubmissionTemplateQuestionId: t.number,
  yesOrNo: optionFromNullable(t.boolean),
  text: optionFromNullable(t.string)
}) satisfies t.Type<BidSubmissionAnswerPost, unknown>;


export type BidSubmissionPostRelatedContentC = t.TypeC<{
  documents: t.ReadonlyArrayC<imported10_DataAndIdC<BidSubmissionDocumentPostC>>,
  answers: t.ReadonlyArrayC<imported11_DataAndIdC<BidSubmissionAnswerPostC>>
}>;
export type BidSubmissionPostRelatedContent = {
  documents: ReadonlyArray<imported10_DataAndId<BidSubmissionDocumentPost>>,
  answers: ReadonlyArray<imported11_DataAndId<BidSubmissionAnswerPost>>
};
export const bidSubmissionPostRelatedContentC: BidSubmissionPostRelatedContentC = t.type({
  documents: t.readonlyArray(imported10_dataAndIdC(bidSubmissionDocumentPostC)),
  answers: t.readonlyArray(imported11_dataAndIdC(bidSubmissionAnswerPostC))
}) satisfies t.Type<BidSubmissionPostRelatedContent, unknown>;


export type BidSubmissionPostC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  rfpId: t.NumberC,
  userId: t.NumberC,
  bidSubmissionTemplateId: t.NumberC,
  firstName: t.StringC,
  lastName: t.StringC,
  organization: t.StringC,
  email: t.StringC,
  interestRate: OptionFromNullableC<BigNumberC>,
  fees: OptionFromNullableC<BigNumberC>,
  additionalComments: OptionFromNullableC<t.StringC>,
  relatedContent: BidSubmissionPostRelatedContentC
}>;
export type BidSubmissionPost = {
  id: O.Option<number>,
  rfpId: number,
  userId: number,
  bidSubmissionTemplateId: number,
  firstName: string,
  lastName: string,
  organization: string,
  email: string,
  interestRate: O.Option<ReadonlyBig>,
  fees: O.Option<ReadonlyBig>,
  additionalComments: O.Option<string>,
  relatedContent: BidSubmissionPostRelatedContent
};
export const bidSubmissionPostC: BidSubmissionPostC = t.type({
  id: optionFromNullable(t.number),
  rfpId: t.number,
  userId: t.number,
  bidSubmissionTemplateId: t.number,
  firstName: t.string,
  lastName: t.string,
  organization: t.string,
  email: t.string,
  interestRate: optionFromNullable(bigNumberC),
  fees: optionFromNullable(bigNumberC),
  additionalComments: optionFromNullable(t.string),
  relatedContent: bidSubmissionPostRelatedContentC
}) satisfies t.Type<BidSubmissionPost, unknown>;


