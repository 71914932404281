import { WithStatusCU as imported626_WithStatusCU, WithStatusCU as imported625_WithStatusCU, WithStatusCU as imported627_WithStatusCU, WithStatusU as imported312_WithStatusU, withModInfoC as imported185_withModInfoC, WithId as imported244_WithId, WithStatusCU as imported624_WithStatusCU, withIdC as imported244_withIdC, WithIdC as imported244_WithIdC, WithModInfo as imported185_WithModInfo, WithModInfoC as imported185_WithModInfoC, WithStatusU as imported313_WithStatusU } from "./threadThrough";
import * as t from "io-ts";
import { TaggedContent as imported156_TaggedContent, TaggedContent as imported155_TaggedContent, TaggedContent as imported157_TaggedContent, taggedContentC as imported157_taggedContentC, TaggedContentC as imported157_TaggedContentC, TaggedContentC as imported155_TaggedContentC, TaggedContentC as imported156_TaggedContentC, taggedContentC as imported155_taggedContentC, taggedContentC as imported156_taggedContentC } from "./taggedContent";
import { RoadShowDataC as imported26_RoadShowDataC, RoadShowData as imported26_RoadShowData, roadShowDataC as imported26_roadShowDataC } from "./roadshow";
import { BondOfferingC as imported25_BondOfferingC, BondOffering as imported25_BondOffering, bondOfferingC as imported25_bondOfferingC } from "./bondOfferingBase";
import { RfpC as imported68_RfpC, Rfp as imported68_Rfp, rfpC as imported65_rfpC } from "./rfpBase";
import { ReadonlyMapFromEntriesC, readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export type RoadShowsListC = t.TypeC<{
  shows: t.ReadonlyArrayC<imported244_WithIdC<imported185_WithModInfoC<imported155_TaggedContentC<imported26_RoadShowDataC>>>>,
  offerings: ReadonlyMapFromEntriesC<t.NumberC, t.ReadonlyArrayC<imported626_WithStatusCU<imported156_TaggedContentC<imported25_BondOfferingC>>>>,
  rfps: ReadonlyMapFromEntriesC<t.NumberC, t.ReadonlyArrayC<imported627_WithStatusCU<imported157_TaggedContentC<imported68_RfpC>>>>
}>;
export type RoadShowsList = {
  shows: ReadonlyArray<imported244_WithId<imported185_WithModInfo<imported155_TaggedContent<imported26_RoadShowData>>>>,
  offerings: ReadonlyMap<number, ReadonlyArray<imported312_WithStatusU<imported156_TaggedContent<imported25_BondOffering>>>>,
  rfps: ReadonlyMap<number, ReadonlyArray<imported313_WithStatusU<imported157_TaggedContent<imported68_Rfp>>>>
};
export const roadShowsListC: RoadShowsListC = t.type({
  shows: t.readonlyArray(imported244_withIdC(imported185_withModInfoC(imported155_taggedContentC(imported26_roadShowDataC)))),
  offerings: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported624_WithStatusCU(imported156_taggedContentC(imported25_bondOfferingC)))),
  rfps: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported625_WithStatusCU(imported157_taggedContentC(imported65_rfpC))))
}) satisfies t.Type<RoadShowsList, unknown>;


