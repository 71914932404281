import { withModInfoC as imported159_withModInfoC, WithModInfo as imported159_WithModInfo, withModInfoC as imported160_withModInfoC, WithModInfo as imported160_WithModInfo, withIdC as imported220_withIdC, WithId as imported221_WithId, WithId as imported220_WithId, withIdC as imported221_withIdC, WithId as imported219_WithId, WithIdC as imported221_WithIdC, WithIdC as imported220_WithIdC, WithIdC as imported219_WithIdC, withIdC as imported219_withIdC, WithModInfoC as imported159_WithModInfoC, WithModInfoC as imported160_WithModInfoC } from "./threadThrough";
import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { ContactFormSubmissionC as imported18_ContactFormSubmissionC, ContactFormSubmission as imported18_ContactFormSubmission, contactFormSubmissionC as imported18_contactFormSubmissionC } from "./contact";
import * as O from "fp-ts/lib/Option";
import { LocalDateTime } from "@js-joda/core";
import { RfpC as imported59_RfpC, Rfp as imported59_Rfp, rfpC as imported56_rfpC } from "./rfpBase";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { BondOfferingDetailsC as imported37_BondOfferingDetailsC, BondOfferingDetails as imported37_BondOfferingDetails, bondOfferingDetailsC as imported37_bondOfferingDetailsC } from "./bondOffering";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";
import { SubscriptionChangeCU as imported9_SubscriptionChangeCU, SubscriptionChangeU as imported4_SubscriptionChangeU, SubscriptionChangeCU as imported8_SubscriptionChangeCU } from "../domaintables/notificationSubscriptions";
import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";

export type InvestorC = t.TypeC<{
  name: t.StringC,
  url: t.StringC
}>;
export type Investor = {
  name: string,
  url: string
};
export const investorC: InvestorC = t.type({
  name: t.string,
  url: t.string
}) satisfies t.Type<Investor, unknown>;


export type DetailsC = t.TypeC<{
  activityOrItemId: t.NumberC,
  investor: InvestorC,
  dateTime: LocalDateTimeFromIsoStringC
}>;
export type Details = {
  activityOrItemId: number,
  investor: Investor,
  dateTime: LocalDateTime
};
export const detailsC: DetailsC = t.type({
  activityOrItemId: t.number,
  investor: investorC,
  dateTime: LocalDateTimeFromIsoStringC
}) satisfies t.Type<Details, unknown>;


export type DocumentC = t.TypeC<{
  id: t.NumberC,
  title: t.StringC,
  url: t.StringC
}>;
export type Document = {
  id: number,
  title: string,
  url: string
};
export const documentC: DocumentC = t.type({
  id: t.number,
  title: t.string,
  url: t.string
}) satisfies t.Type<Document, unknown>;


export type EmailC = t.TypeC<{
  subject: t.StringC
}>;
export type Email = {
  subject: string
};
export const emailC: EmailC = t.type({
  subject: t.string
}) satisfies t.Type<Email, unknown>;


export type PageC = t.TypeC<{
  title: t.StringC
}>;
export type Page = {
  title: string
};
export const pageC: PageC = t.type({
  title: t.string
}) satisfies t.Type<Page, unknown>;


export type RoadShowC = t.TypeC<{
  title: t.StringC,
  url: t.StringC
}>;
export type RoadShow = {
  title: string,
  url: string
};
export const roadShowC: RoadShowC = t.type({
  title: t.string,
  url: t.string
}) satisfies t.Type<RoadShow, unknown>;


export type DocumentDownloadedC = t.TypeC<{
  _tag: t.LiteralC<`DocumentDownloaded`>,
  details: DetailsC,
  document: DocumentC
}>;
export type DocumentDownloaded = {
  _tag: `DocumentDownloaded`,
  details: Details,
  document: Document
};
export const documentDownloadedC: DocumentDownloadedC = t.type({
  _tag: t.literal(`DocumentDownloaded`),
  details: detailsC,
  document: documentC
}) satisfies t.Type<DocumentDownloaded, unknown>;


export type DocumentViewedC = t.TypeC<{
  _tag: t.LiteralC<`DocumentViewed`>,
  details: DetailsC,
  document: DocumentC
}>;
export type DocumentViewed = {
  _tag: `DocumentViewed`,
  details: Details,
  document: Document
};
export const documentViewedC: DocumentViewedC = t.type({
  _tag: t.literal(`DocumentViewed`),
  details: detailsC,
  document: documentC
}) satisfies t.Type<DocumentViewed, unknown>;


export type OfferingViewedC = t.TypeC<{
  _tag: t.LiteralC<`OfferingViewed`>,
  details: DetailsC,
  offering: imported219_WithIdC<imported159_WithModInfoC<imported37_BondOfferingDetailsC>>
}>;
export type OfferingViewed = {
  _tag: `OfferingViewed`,
  details: Details,
  offering: imported219_WithId<imported159_WithModInfo<imported37_BondOfferingDetails>>
};
export const offeringViewedC: OfferingViewedC = t.type({
  _tag: t.literal(`OfferingViewed`),
  details: detailsC,
  offering: imported219_withIdC(imported159_withModInfoC(imported37_bondOfferingDetailsC))
}) satisfies t.Type<OfferingViewed, unknown>;


export type RfpViewedC = t.TypeC<{
  _tag: t.LiteralC<`RfpViewed`>,
  details: DetailsC,
  rfp: imported220_WithIdC<imported160_WithModInfoC<imported59_RfpC>>
}>;
export type RfpViewed = {
  _tag: `RfpViewed`,
  details: Details,
  rfp: imported220_WithId<imported160_WithModInfo<imported59_Rfp>>
};
export const rfpViewedC: RfpViewedC = t.type({
  _tag: t.literal(`RfpViewed`),
  details: detailsC,
  rfp: imported220_withIdC(imported160_withModInfoC(imported56_rfpC))
}) satisfies t.Type<RfpViewed, unknown>;


export type ContactFormSubmittedC = t.TypeC<{
  _tag: t.LiteralC<`ContactFormSubmitted`>,
  details: DetailsC,
  submissionInfo: imported221_WithIdC<imported18_ContactFormSubmissionC>
}>;
export type ContactFormSubmitted = {
  _tag: `ContactFormSubmitted`,
  details: Details,
  submissionInfo: imported221_WithId<imported18_ContactFormSubmission>
};
export const contactFormSubmittedC: ContactFormSubmittedC = t.type({
  _tag: t.literal(`ContactFormSubmitted`),
  details: detailsC,
  submissionInfo: imported221_withIdC(imported18_contactFormSubmissionC)
}) satisfies t.Type<ContactFormSubmitted, unknown>;


export type NotificationSubscriptionsChangedC = t.TypeC<{
  _tag: t.LiteralC<`NotificationSubscriptionsChanged`>,
  details: DetailsC,
  subscriptions: ReadonlyNonEmptyArrayType<imported9_SubscriptionChangeCU>
}>;
export type NotificationSubscriptionsChanged = {
  _tag: `NotificationSubscriptionsChanged`,
  details: Details,
  subscriptions: RNEA.ReadonlyNonEmptyArray<imported4_SubscriptionChangeU>
};
export const notificationSubscriptionsChangedC: NotificationSubscriptionsChangedC = t.type({
  _tag: t.literal(`NotificationSubscriptionsChanged`),
  details: detailsC,
  subscriptions: readonlyNonEmptyArrayC(imported8_SubscriptionChangeCU)
}) satisfies t.Type<NotificationSubscriptionsChanged, unknown>;


export type SiteViewedC = t.TypeC<{
  _tag: t.LiteralC<`SiteViewed`>,
  details: DetailsC,
  pages: ReadonlyNonEmptyArrayType<PageC>
}>;
export type SiteViewed = {
  _tag: `SiteViewed`,
  details: Details,
  pages: RNEA.ReadonlyNonEmptyArray<Page>
};
export const siteViewedC: SiteViewedC = t.type({
  _tag: t.literal(`SiteViewed`),
  details: detailsC,
  pages: readonlyNonEmptyArrayC(pageC)
}) satisfies t.Type<SiteViewed, unknown>;


export type RoadShowViewedC = t.TypeC<{
  _tag: t.LiteralC<`RoadShowViewed`>,
  details: DetailsC,
  roadShow: RoadShowC,
  furthestSeek: OptionFromNullableC<t.NumberC>
}>;
export type RoadShowViewed = {
  _tag: `RoadShowViewed`,
  details: Details,
  roadShow: RoadShow,
  furthestSeek: O.Option<number>
};
export const roadShowViewedC: RoadShowViewedC = t.type({
  _tag: t.literal(`RoadShowViewed`),
  details: detailsC,
  roadShow: roadShowC,
  furthestSeek: optionFromNullable(t.number)
}) satisfies t.Type<RoadShowViewed, unknown>;


export type NewsC = t.TypeC<{
  title: t.StringC,
  body: t.StringC,
  url: t.StringC
}>;
export type News = {
  title: string,
  body: string,
  url: string
};
export const newsC: NewsC = t.type({
  title: t.string,
  body: t.string,
  url: t.string
}) satisfies t.Type<News, unknown>;


export type NewsViewedC = t.TypeC<{
  _tag: t.LiteralC<`NewsViewed`>,
  details: DetailsC,
  news: NewsC
}>;
export type NewsViewed = {
  _tag: `NewsViewed`,
  details: Details,
  news: News
};
export const newsViewedC: NewsViewedC = t.type({
  _tag: t.literal(`NewsViewed`),
  details: detailsC,
  news: newsC
}) satisfies t.Type<NewsViewed, unknown>;


export type ProjectIAC = t.TypeC<{
  title: t.StringC,
  url: t.StringC
}>;
export type ProjectIA = {
  title: string,
  url: string
};
export const projectIAC: ProjectIAC = t.type({
  title: t.string,
  url: t.string
}) satisfies t.Type<ProjectIA, unknown>;


export type ProjectViewedC = t.TypeC<{
  _tag: t.LiteralC<`ProjectViewed`>,
  details: DetailsC,
  project: ProjectIAC
}>;
export type ProjectViewed = {
  _tag: `ProjectViewed`,
  details: Details,
  project: ProjectIA
};
export const projectViewedC: ProjectViewedC = t.type({
  _tag: t.literal(`ProjectViewed`),
  details: detailsC,
  project: projectIAC
}) satisfies t.Type<ProjectViewed, unknown>;


export type EmailOpenedC = t.TypeC<{
  _tag: t.LiteralC<`EmailOpened`>,
  details: DetailsC,
  email: EmailC,
  receivedDate: LocalDateTimeFromIsoStringC
}>;
export type EmailOpened = {
  _tag: `EmailOpened`,
  details: Details,
  email: Email,
  receivedDate: LocalDateTime
};
export const emailOpenedC: EmailOpenedC = t.type({
  _tag: t.literal(`EmailOpened`),
  details: detailsC,
  email: emailC,
  receivedDate: LocalDateTimeFromIsoStringC
}) satisfies t.Type<EmailOpened, unknown>;


export type EmailLinkClickedC = t.TypeC<{
  _tag: t.LiteralC<`EmailLinkClicked`>,
  details: DetailsC,
  email: EmailC,
  receivedDate: LocalDateTimeFromIsoStringC
}>;
export type EmailLinkClicked = {
  _tag: `EmailLinkClicked`,
  details: Details,
  email: Email,
  receivedDate: LocalDateTime
};
export const emailLinkClickedC: EmailLinkClickedC = t.type({
  _tag: t.literal(`EmailLinkClicked`),
  details: detailsC,
  email: emailC,
  receivedDate: LocalDateTimeFromIsoStringC
}) satisfies t.Type<EmailLinkClicked, unknown>;


export type EmailReceivedC = t.TypeC<{
  _tag: t.LiteralC<`EmailReceived`>,
  details: DetailsC,
  email: EmailC
}>;
export type EmailReceived = {
  _tag: `EmailReceived`,
  details: Details,
  email: Email
};
export const emailReceivedC: EmailReceivedC = t.type({
  _tag: t.literal(`EmailReceived`),
  details: detailsC,
  email: emailC
}) satisfies t.Type<EmailReceived, unknown>;


export type IssuerLinkIAC = t.TypeC<{
  name: t.StringC,
  url: t.StringC,
  description: OptionFromNullableC<MarkdownC>
}>;
export type IssuerLinkIA = {
  name: string,
  url: string,
  description: O.Option<Markdown>
};
export const issuerLinkIAC: IssuerLinkIAC = t.type({
  name: t.string,
  url: t.string,
  description: optionFromNullable(markdownC)
}) satisfies t.Type<IssuerLinkIA, unknown>;


export type IssuerLinkClickedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerLinkClicked`>,
  details: DetailsC,
  issuerLink: IssuerLinkIAC
}>;
export type IssuerLinkClicked = {
  _tag: `IssuerLinkClicked`,
  details: Details,
  issuerLink: IssuerLinkIA
};
export const issuerLinkClickedC: IssuerLinkClickedC = t.type({
  _tag: t.literal(`IssuerLinkClicked`),
  details: detailsC,
  issuerLink: issuerLinkIAC
}) satisfies t.Type<IssuerLinkClicked, unknown>;


export const allInvestorActivityC = [documentDownloadedC, documentViewedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, notificationSubscriptionsChangedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, emailOpenedC, emailLinkClickedC, emailReceivedC, issuerLinkClickedC] as const;
export const allInvestorActivityNames = [`DocumentDownloaded`, `DocumentViewed`, `OfferingViewed`, `RfpViewed`, `ContactFormSubmitted`, `NotificationSubscriptionsChanged`, `SiteViewed`, `RoadShowViewed`, `NewsViewed`, `ProjectViewed`, `EmailOpened`, `EmailLinkClicked`, `EmailReceived`, `IssuerLinkClicked`] as const;
export type InvestorActivityName = (typeof allInvestorActivityNames)[number];

export type InvestorActivityCU = t.UnionC<[DocumentDownloadedC, DocumentViewedC, OfferingViewedC, RfpViewedC, ContactFormSubmittedC, NotificationSubscriptionsChangedC, SiteViewedC, RoadShowViewedC, NewsViewedC, ProjectViewedC, EmailOpenedC, EmailLinkClickedC, EmailReceivedC, IssuerLinkClickedC]>;
export type InvestorActivityU = DocumentDownloaded | DocumentViewed | OfferingViewed | RfpViewed | ContactFormSubmitted | NotificationSubscriptionsChanged | SiteViewed | RoadShowViewed | NewsViewed | ProjectViewed | EmailOpened | EmailLinkClicked | EmailReceived | IssuerLinkClicked;
export const InvestorActivityCU: InvestorActivityCU = t.union([documentDownloadedC, documentViewedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, notificationSubscriptionsChangedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, emailOpenedC, emailLinkClickedC, emailReceivedC, issuerLinkClickedC]) satisfies t.Type<InvestorActivityU, unknown>;

export type InvestorActivityMap<A> = { [K in InvestorActivityName]: A };


export const allDashboardFeedActivityC = [documentDownloadedC, documentViewedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, notificationSubscriptionsChangedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, emailOpenedC, emailLinkClickedC, emailReceivedC, issuerLinkClickedC] as const;
export const allDashboardFeedActivityNames = [`DocumentDownloaded`, `DocumentViewed`, `OfferingViewed`, `RfpViewed`, `ContactFormSubmitted`, `NotificationSubscriptionsChanged`, `SiteViewed`, `RoadShowViewed`, `NewsViewed`, `ProjectViewed`, `EmailOpened`, `EmailLinkClicked`, `EmailReceived`, `IssuerLinkClicked`] as const;
export type DashboardFeedActivityName = (typeof allDashboardFeedActivityNames)[number];

export type DashboardFeedActivityCU = t.UnionC<[DocumentDownloadedC, DocumentViewedC, OfferingViewedC, RfpViewedC, ContactFormSubmittedC, NotificationSubscriptionsChangedC, SiteViewedC, RoadShowViewedC, NewsViewedC, ProjectViewedC, EmailOpenedC, EmailLinkClickedC, EmailReceivedC, IssuerLinkClickedC]>;
export type DashboardFeedActivityU = DocumentDownloaded | DocumentViewed | OfferingViewed | RfpViewed | ContactFormSubmitted | NotificationSubscriptionsChanged | SiteViewed | RoadShowViewed | NewsViewed | ProjectViewed | EmailOpened | EmailLinkClicked | EmailReceived | IssuerLinkClicked;
export const DashboardFeedActivityCU: DashboardFeedActivityCU = t.union([documentDownloadedC, documentViewedC, offeringViewedC, rfpViewedC, contactFormSubmittedC, notificationSubscriptionsChangedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, emailOpenedC, emailLinkClickedC, emailReceivedC, issuerLinkClickedC]) satisfies t.Type<DashboardFeedActivityU, unknown>;

export type DashboardFeedActivityMap<A> = { [K in DashboardFeedActivityName]: A };


export const allInvestorActivityTabC = [offeringViewedC, rfpViewedC, contactFormSubmittedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, issuerLinkClickedC, emailOpenedC, emailLinkClickedC, emailReceivedC, documentDownloadedC, documentViewedC, notificationSubscriptionsChangedC] as const;
export const allInvestorActivityTabNames = [`OfferingViewed`, `RfpViewed`, `ContactFormSubmitted`, `SiteViewed`, `RoadShowViewed`, `NewsViewed`, `ProjectViewed`, `IssuerLinkClicked`, `EmailOpened`, `EmailLinkClicked`, `EmailReceived`, `DocumentDownloaded`, `DocumentViewed`, `NotificationSubscriptionsChanged`] as const;
export type InvestorActivityTabName = (typeof allInvestorActivityTabNames)[number];

export type InvestorActivityTabCU = t.UnionC<[OfferingViewedC, RfpViewedC, ContactFormSubmittedC, SiteViewedC, RoadShowViewedC, NewsViewedC, ProjectViewedC, IssuerLinkClickedC, EmailOpenedC, EmailLinkClickedC, EmailReceivedC, DocumentDownloadedC, DocumentViewedC, NotificationSubscriptionsChangedC]>;
export type InvestorActivityTabU = OfferingViewed | RfpViewed | ContactFormSubmitted | SiteViewed | RoadShowViewed | NewsViewed | ProjectViewed | IssuerLinkClicked | EmailOpened | EmailLinkClicked | EmailReceived | DocumentDownloaded | DocumentViewed | NotificationSubscriptionsChanged;
export const InvestorActivityTabCU: InvestorActivityTabCU = t.union([offeringViewedC, rfpViewedC, contactFormSubmittedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, issuerLinkClickedC, emailOpenedC, emailLinkClickedC, emailReceivedC, documentDownloadedC, documentViewedC, notificationSubscriptionsChangedC]) satisfies t.Type<InvestorActivityTabU, unknown>;

export type InvestorActivityTabMap<A> = { [K in InvestorActivityTabName]: A };


export const allSiteActivityC = [offeringViewedC, rfpViewedC, contactFormSubmittedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, issuerLinkClickedC] as const;
export const allSiteActivityNames = [`OfferingViewed`, `RfpViewed`, `ContactFormSubmitted`, `SiteViewed`, `RoadShowViewed`, `NewsViewed`, `ProjectViewed`, `IssuerLinkClicked`] as const;
export type SiteActivityName = (typeof allSiteActivityNames)[number];

export type SiteActivityCU = t.UnionC<[OfferingViewedC, RfpViewedC, ContactFormSubmittedC, SiteViewedC, RoadShowViewedC, NewsViewedC, ProjectViewedC, IssuerLinkClickedC]>;
export type SiteActivityU = OfferingViewed | RfpViewed | ContactFormSubmitted | SiteViewed | RoadShowViewed | NewsViewed | ProjectViewed | IssuerLinkClicked;
export const SiteActivityCU: SiteActivityCU = t.union([offeringViewedC, rfpViewedC, contactFormSubmittedC, siteViewedC, roadShowViewedC, newsViewedC, projectViewedC, issuerLinkClickedC]) satisfies t.Type<SiteActivityU, unknown>;

export type SiteActivityMap<A> = { [K in SiteActivityName]: A };


export const allEmailActivityC = [emailOpenedC, emailLinkClickedC, emailReceivedC] as const;
export const allEmailActivityNames = [`EmailOpened`, `EmailLinkClicked`, `EmailReceived`] as const;
export type EmailActivityName = (typeof allEmailActivityNames)[number];

export type EmailActivityCU = t.UnionC<[EmailOpenedC, EmailLinkClickedC, EmailReceivedC]>;
export type EmailActivityU = EmailOpened | EmailLinkClicked | EmailReceived;
export const EmailActivityCU: EmailActivityCU = t.union([emailOpenedC, emailLinkClickedC, emailReceivedC]) satisfies t.Type<EmailActivityU, unknown>;

export type EmailActivityMap<A> = { [K in EmailActivityName]: A };


export const allDocumentActivityC = [documentDownloadedC, documentViewedC] as const;
export const allDocumentActivityNames = [`DocumentDownloaded`, `DocumentViewed`] as const;
export type DocumentActivityName = (typeof allDocumentActivityNames)[number];

export type DocumentActivityCU = t.UnionC<[DocumentDownloadedC, DocumentViewedC]>;
export type DocumentActivityU = DocumentDownloaded | DocumentViewed;
export const DocumentActivityCU: DocumentActivityCU = t.union([documentDownloadedC, documentViewedC]) satisfies t.Type<DocumentActivityU, unknown>;

export type DocumentActivityMap<A> = { [K in DocumentActivityName]: A };


export const allNotificationActivityC = [notificationSubscriptionsChangedC] as const;
export const allNotificationActivityNames = [`NotificationSubscriptionsChanged`] as const;
export type NotificationActivityName = (typeof allNotificationActivityNames)[number];

export type NotificationActivityCU = NotificationSubscriptionsChangedC;
export type NotificationActivityU = NotificationSubscriptionsChanged;
export const NotificationActivityCU: NotificationActivityCU = notificationSubscriptionsChangedC satisfies t.Type<NotificationActivityU, unknown>;

export type NotificationActivityMap<A> = { [K in NotificationActivityName]: A };


export type TabCountC<A1 extends t.Mixed> = t.TypeC<{
  t: OptionFromNullableC<A1>,
  count: t.NumberC
}>;
export type TabCount<A1> = {
  t: O.Option<A1>,
  count: number
};
export const tabCountC = <A1 extends t.Mixed>(A1: A1): TabCountC<A1> => t.type({
  t: optionFromNullable(A1),
  count: t.number
}) satisfies t.Type<TabCount<t.TypeOf<A1>>, unknown>;


export type TabCountsC = t.TypeC<{
  all: TabCountC<InvestorActivityCU>,
  site: TabCountC<SiteActivityCU>,
  email: TabCountC<EmailActivityCU>,
  document: TabCountC<DocumentActivityCU>,
  notification: TabCountC<NotificationActivityCU>
}>;
export type TabCounts = {
  all: TabCount<InvestorActivityU>,
  site: TabCount<SiteActivityU>,
  email: TabCount<EmailActivityU>,
  document: TabCount<DocumentActivityU>,
  notification: TabCount<NotificationActivityU>
};
export const tabCountsC: TabCountsC = t.type({
  all: tabCountC(InvestorActivityCU),
  site: tabCountC(SiteActivityCU),
  email: tabCountC(EmailActivityCU),
  document: tabCountC(DocumentActivityCU),
  notification: tabCountC(NotificationActivityCU)
}) satisfies t.Type<TabCounts, unknown>;


