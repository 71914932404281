import * as O from "fp-ts/lib/Option";
import * as t from "io-ts";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { withIdC as imported199_withIdC, WithStatusCU as imported437_WithStatusCU, WithId as imported199_WithId, WithIdC as imported199_WithIdC, withIdC as imported200_withIdC, WithId as imported200_WithId, WithStatusCU as imported436_WithStatusCU, WithIdC as imported200_WithIdC, WithStatusU as imported218_WithStatusU } from "./threadThrough";

export type IssuerLinkSectionC = t.TypeC<{
  name: t.StringC,
  sortOrder: t.NumberC
}>;
export type IssuerLinkSection = {
  name: string,
  sortOrder: number
};
export const issuerLinkSectionC: IssuerLinkSectionC = t.type({
  name: t.string,
  sortOrder: t.number
}) satisfies t.Type<IssuerLinkSection, unknown>;


export type IssuerLinkC = t.TypeC<{
  name: t.StringC,
  url: t.StringC,
  description: OptionFromNullableC<t.StringC>,
  section: imported199_WithIdC<IssuerLinkSectionC>,
  sortOrder: t.NumberC
}>;
export type IssuerLink = {
  name: string,
  url: string,
  description: O.Option<string>,
  section: imported199_WithId<IssuerLinkSection>,
  sortOrder: number
};
export const issuerLinkC: IssuerLinkC = t.type({
  name: t.string,
  url: t.string,
  description: optionFromNullable(t.string),
  section: imported199_withIdC(issuerLinkSectionC),
  sortOrder: t.number
}) satisfies t.Type<IssuerLink, unknown>;


export type LinksBySectionC = t.TypeC<{
  section: imported200_WithIdC<IssuerLinkSectionC>,
  links: t.ReadonlyArrayC<imported437_WithStatusCU<IssuerLinkC>>
}>;
export type LinksBySection = {
  section: imported200_WithId<IssuerLinkSection>,
  links: ReadonlyArray<imported218_WithStatusU<IssuerLink>>
};
export const linksBySectionC: LinksBySectionC = t.type({
  section: imported200_withIdC(issuerLinkSectionC),
  links: t.readonlyArray(imported436_WithStatusCU(issuerLinkC))
}) satisfies t.Type<LinksBySection, unknown>;


export type LinkPostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  name: t.StringC,
  url: t.StringC,
  description: OptionFromNullableC<t.StringC>,
  sectionId: t.NumberC,
  sortOrder: t.NumberC
}>;
export type LinkPostBody = {
  id: O.Option<number>,
  name: string,
  url: string,
  description: O.Option<string>,
  sectionId: number,
  sortOrder: number
};
export const linkPostBodyC: LinkPostBodyC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  url: t.string,
  description: optionFromNullable(t.string),
  sectionId: t.number,
  sortOrder: t.number
}) satisfies t.Type<LinkPostBody, unknown>;


export type LinkSectionPostC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  name: t.StringC,
  sortOrder: t.NumberC
}>;
export type LinkSectionPost = {
  id: O.Option<number>,
  name: string,
  sortOrder: number
};
export const linkSectionPostC: LinkSectionPostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  sortOrder: t.number
}) satisfies t.Type<LinkSectionPost, unknown>;


