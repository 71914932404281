import { WithStatusCU as imported745_WithStatusCU, WithStatusU as imported372_WithStatusU, WithStatusCU as imported744_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { Media as imported80_Media, MediaUploadResponseC as imported29_MediaUploadResponseC, MediaUploadResponse as imported29_MediaUploadResponse, mediaUploadResponseC as imported29_mediaUploadResponseC, MediaC as imported80_MediaC, MediaUploadResponseC as imported28_MediaUploadResponseC, mediaUploadResponseC as imported28_mediaUploadResponseC, mediaC as imported76_mediaC, MediaUploadResponse as imported28_MediaUploadResponse } from "./media";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { PhotoEditableCU as imported29_PhotoEditableCU, PhotoEditableU as imported13_PhotoEditableU, PhotoEditableCU as imported27_PhotoEditableCU, PhotoEditableU as imported14_PhotoEditableU, PhotoEditableCU as imported26_PhotoEditableCU, PhotoEditableCU as imported28_PhotoEditableCU } from "../domaintables/pages";
import { SectorCU as imported39_SectorCU, SectorU as imported19_SectorU, SectorCU as imported38_SectorCU } from "../domaintables/sectors";

export type HeaderPhotoC = t.TypeC<{
  page: imported27_PhotoEditableCU,
  media: imported745_WithStatusCU<imported80_MediaC>,
  commonHeaderPhotoId: OptionFromNullableC<t.NumberC>
}>;
export type HeaderPhoto = {
  page: imported13_PhotoEditableU,
  media: imported372_WithStatusU<imported80_Media>,
  commonHeaderPhotoId: O.Option<number>
};
export const headerPhotoC: HeaderPhotoC = t.type({
  page: imported26_PhotoEditableCU,
  media: imported744_WithStatusCU(imported76_mediaC),
  commonHeaderPhotoId: optionFromNullable(t.number)
}) satisfies t.Type<HeaderPhoto, unknown>;


export type HeaderPhotoPostC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  page: imported29_PhotoEditableCU,
  uploadResponse: OptionFromNullableC<imported28_MediaUploadResponseC>,
  commonHeaderPhotoId: OptionFromNullableC<t.NumberC>
}>;
export type HeaderPhotoPost = {
  id: O.Option<number>,
  page: imported14_PhotoEditableU,
  uploadResponse: O.Option<imported28_MediaUploadResponse>,
  commonHeaderPhotoId: O.Option<number>
};
export const headerPhotoPostC: HeaderPhotoPostC = t.type({
  id: optionFromNullable(t.number),
  page: imported28_PhotoEditableCU,
  uploadResponse: optionFromNullable(imported28_mediaUploadResponseC),
  commonHeaderPhotoId: optionFromNullable(t.number)
}) satisfies t.Type<HeaderPhotoPost, unknown>;


export type CommonHeaderPhotoC = t.TypeC<{
  media: imported29_MediaUploadResponseC,
  sector: imported39_SectorCU
}>;
export type CommonHeaderPhoto = {
  media: imported29_MediaUploadResponse,
  sector: imported19_SectorU
};
export const commonHeaderPhotoC: CommonHeaderPhotoC = t.type({
  media: imported29_mediaUploadResponseC,
  sector: imported38_SectorCU
}) satisfies t.Type<CommonHeaderPhoto, unknown>;


