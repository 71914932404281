import { WithId as imported204_WithId, hasManyLinkC as imported89_hasManyLinkC, WithStatusU as imported224_WithStatusU, WithStatusCU as imported449_WithStatusCU, WithStatusCU as imported451_WithStatusCU, WithStatusCU as imported452_WithStatusCU, WithStatusU as imported227_WithStatusU, HasManyLink as imported89_HasManyLink, WithStatusCU as imported442_WithStatusCU, WithStatusCU as imported450_WithStatusCU, WithStatusCU as imported439_WithStatusCU, WithStatusCU as imported443_WithStatusCU, WithStatusU as imported226_WithStatusU, WithStatusU as imported223_WithStatusU, WithStatusU as imported222_WithStatusU, HasManyLink as imported88_HasManyLink, WithStatusU as imported220_WithStatusU, WithIdC as imported204_WithIdC, WithStatusCU as imported454_WithStatusCU, WithStatusCU as imported438_WithStatusCU, WithIdC as imported202_WithIdC, HasManyLinkC as imported89_HasManyLinkC, WithStatusCU as imported455_WithStatusCU, WithStatusCU as imported453_WithStatusCU, WithId as imported202_WithId, WithStatusU as imported219_WithStatusU, WithStatusCU as imported446_WithStatusCU, hasManyLinkC as imported88_hasManyLinkC, withIdC as imported204_withIdC, WithStatusCU as imported448_WithStatusCU, HasManyLink as imported87_HasManyLink, WithStatusU as imported221_WithStatusU, WithStatusCU as imported440_WithStatusCU, WithStatusU as imported225_WithStatusU, WithStatusCU as imported444_WithStatusCU, WithStatusCU as imported441_WithStatusCU, HasManyLinkC as imported87_HasManyLinkC, HasManyLinkC as imported88_HasManyLinkC, hasManyLinkC as imported87_hasManyLinkC, WithId as imported203_WithId, WithIdC as imported203_WithIdC, withIdC as imported203_withIdC, WithStatusCU as imported447_WithStatusCU, withIdC as imported202_withIdC, WithStatusCU as imported445_WithStatusCU } from "./threadThrough";
import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { ProgramRating as imported11_ProgramRating, IssuerRating as imported11_IssuerRating, ProgramRating as imported13_ProgramRating, bondRatingC as imported2_bondRatingC, ProgramRating as imported12_ProgramRating, IssuerRating as imported12_IssuerRating, programRatingC as imported9_programRatingC, ProgramRatingC as imported11_ProgramRatingC, BondRatingC as imported4_BondRatingC, issuerRatingC as imported9_issuerRatingC, programRatingC as imported8_programRatingC, issuerRatingC as imported10_issuerRatingC, issuerRatingC as imported8_issuerRatingC, IssuerRatingC as imported11_IssuerRatingC, IssuerRatingC as imported12_IssuerRatingC, BondRating as imported4_BondRating, IssuerRating as imported13_IssuerRating, ProgramRatingC as imported13_ProgramRatingC, ProgramRatingC as imported12_ProgramRatingC, programRatingC as imported10_programRatingC, IssuerRatingC as imported13_IssuerRatingC } from "./ratingBase";
import { RatingAgencyCU as imported50_RatingAgencyCU, RatingAgencyU as imported28_RatingAgencyU, RatingAgencyCU as imported56_RatingAgencyCU, RatingAgencyCU as imported54_RatingAgencyCU, RatingAgencyU as imported27_RatingAgencyU, RatingAgencyCU as imported58_RatingAgencyCU, RatingAgencyU as imported25_RatingAgencyU, RatingAgencyCU as imported47_RatingAgencyCU, RatingAgencyCU as imported46_RatingAgencyCU, RatingAgencyCU as imported51_RatingAgencyCU, RatingAgencyCU as imported59_RatingAgencyCU, RatingAgencyU as imported26_RatingAgencyU, RatingAgencyCU as imported48_RatingAgencyCU, RatingAgencyCU as imported57_RatingAgencyCU, RatingAgencyCU as imported49_RatingAgencyCU, RatingAgencyU as imported23_RatingAgencyU, RatingAgencyU as imported29_RatingAgencyU, RatingAgencyU as imported24_RatingAgencyU, RatingAgencyCU as imported53_RatingAgencyCU, RatingAgencyCU as imported52_RatingAgencyCU, RatingAgencyCU as imported55_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { TaggedContentC as imported123_TaggedContentC, TaggedContent as imported123_TaggedContent, taggedContentC as imported123_taggedContentC } from "./taggedContent";
import * as O from "fp-ts/lib/Option";
import { BondProgram as imported14_BondProgram, BondProgram as imported15_BondProgram, BondProgramC as imported14_BondProgramC, bondProgramC as imported11_bondProgramC, bondProgramC as imported13_bondProgramC, BondProgramC as imported16_BondProgramC, bondProgramC as imported12_bondProgramC, BondProgramC as imported15_BondProgramC, BondProgram as imported16_BondProgram } from "./bondProgramBase";
import { LocalDate } from "@js-joda/core";
import { AnalystC as imported4_AnalystC, Analyst as imported4_Analyst, analystC as imported3_analystC } from "./analyst";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { SortOrderC as imported19_SortOrderC, SortOrder as imported19_SortOrder, sortOrderC as imported19_sortOrderC } from "./sortOrder";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { RelatedAnalystLink as imported3_RelatedAnalystLink, RelatedRatingLink as imported2_RelatedRatingLink, relatedAnalystLinkC as imported2_relatedAnalystLinkC, IsAttached as imported11_IsAttached, RelatedAnalystLinkC as imported3_RelatedAnalystLinkC, relatedDocumentLinkC as imported18_relatedDocumentLinkC, isAttachedC as imported11_isAttachedC, RelatedAnalystLink as imported2_RelatedAnalystLink, PostRelatedAndAttached as imported7_PostRelatedAndAttached, PostRelatedAndAttachedC as imported7_PostRelatedAndAttachedC, RelatedDocumentLink as imported19_RelatedDocumentLink, RelatedDocumentLinkC as imported19_RelatedDocumentLinkC, RelatedAnalystLinkC as imported2_RelatedAnalystLinkC, RelatedDocumentLinkC as imported18_RelatedDocumentLinkC, IsAttachedC as imported11_IsAttachedC, relatedAnalystLinkC as imported3_relatedAnalystLinkC, PostRelatedC as imported32_PostRelatedC, RelatedRatingLinkC as imported2_RelatedRatingLinkC, PostRelated as imported32_PostRelated, postRelatedAndAttachedC as imported7_postRelatedAndAttachedC, RelatedDocumentLink as imported18_RelatedDocumentLink, postRelatedC as imported32_postRelatedC, relatedDocumentLinkC as imported19_relatedDocumentLinkC, relatedRatingLinkC as imported2_relatedRatingLinkC } from "./relatedContent";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";
import { MediaUploadResponseWithCategoryC as imported7_MediaUploadResponseWithCategoryC, mediaC as imported45_mediaC, MediaUploadResponseWithCategory as imported7_MediaUploadResponseWithCategory, Media as imported47_Media, MediaC as imported47_MediaC, mediaUploadResponseWithCategoryC as imported7_mediaUploadResponseWithCategoryC } from "./media";
import { LocalDateC } from "../../codecs/localDate";

export type RatingRelatedContentC = t.TypeC<{
  documents: t.ReadonlyArrayC<imported87_HasManyLinkC<imported440_WithStatusCU<imported123_TaggedContentC<imported47_MediaC>>, imported11_IsAttachedC<imported18_RelatedDocumentLinkC>>>,
  analysts: t.ReadonlyArrayC<imported88_HasManyLinkC<imported441_WithStatusCU<imported4_AnalystC>, imported2_RelatedAnalystLinkC>>
}>;
export type RatingRelatedContent = {
  documents: ReadonlyArray<imported87_HasManyLink<imported219_WithStatusU<imported123_TaggedContent<imported47_Media>>, imported11_IsAttached<imported18_RelatedDocumentLink>>>,
  analysts: ReadonlyArray<imported88_HasManyLink<imported220_WithStatusU<imported4_Analyst>, imported2_RelatedAnalystLink>>
};
export const ratingRelatedContentC: RatingRelatedContentC = t.type({
  documents: t.readonlyArray(imported87_hasManyLinkC(imported438_WithStatusCU(imported123_taggedContentC(imported45_mediaC)), imported11_isAttachedC(imported18_relatedDocumentLinkC))),
  analysts: t.readonlyArray(imported88_hasManyLinkC(imported439_WithStatusCU(imported3_analystC), imported2_relatedAnalystLinkC))
}) satisfies t.Type<RatingRelatedContent, unknown>;


export type RatingWithRelatedContentC<A1 extends t.Mixed> = t.TypeC<{
  rating: A1,
  relatedContent: RatingRelatedContentC
}>;
export type RatingWithRelatedContent<A1> = {
  rating: A1,
  relatedContent: RatingRelatedContent
};
export const ratingWithRelatedContentC = <A1 extends t.Mixed>(A1: A1): RatingWithRelatedContentC<A1> => t.type({
  rating: A1,
  relatedContent: ratingRelatedContentC
}) satisfies t.Type<RatingWithRelatedContent<t.TypeOf<A1>>, unknown>;


export type IssuerRatingsC = t.ReadonlyArrayC<t.TupleC<[imported47_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported443_WithStatusCU<imported11_IssuerRatingC>>]>>;
export type IssuerRatings = ReadonlyArray<[imported23_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported221_WithStatusU<imported11_IssuerRating>>]>;
export const issuerRatingsC: IssuerRatingsC = t.readonlyArray(t.tuple([imported46_RatingAgencyCU, readonlyNonEmptyArrayC(imported442_WithStatusCU(imported8_issuerRatingC))])) satisfies t.Type<IssuerRatings, unknown>;


export type IssuerRatingsWithRelatedContentC = t.ReadonlyArrayC<t.TupleC<[imported49_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported445_WithStatusCU<RatingWithRelatedContentC<imported12_IssuerRatingC>>>]>>;
export type IssuerRatingsWithRelatedContent = ReadonlyArray<[imported24_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported222_WithStatusU<RatingWithRelatedContent<imported12_IssuerRating>>>]>;
export const issuerRatingsWithRelatedContentC: IssuerRatingsWithRelatedContentC = t.readonlyArray(t.tuple([imported48_RatingAgencyCU, readonlyNonEmptyArrayC(imported444_WithStatusCU(ratingWithRelatedContentC(imported9_issuerRatingC)))])) satisfies t.Type<IssuerRatingsWithRelatedContent, unknown>;


export type ProgramRatingsC = t.ReadonlyArrayC<t.TupleC<[imported202_WithIdC<imported14_BondProgramC>, ReadonlyNonEmptyArrayType<t.TupleC<[imported51_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported447_WithStatusCU<imported11_ProgramRatingC>>]>>]>>;
export type ProgramRatings = ReadonlyArray<[imported202_WithId<imported14_BondProgram>, RNEA.ReadonlyNonEmptyArray<[imported25_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported223_WithStatusU<imported11_ProgramRating>>]>]>;
export const programRatingsC: ProgramRatingsC = t.readonlyArray(t.tuple([imported202_withIdC(imported11_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported50_RatingAgencyCU, readonlyNonEmptyArrayC(imported446_WithStatusCU(imported8_programRatingC))]))])) satisfies t.Type<ProgramRatings, unknown>;


export type ProgramRatingsWithRelatedContentC = t.ReadonlyArrayC<t.TupleC<[imported203_WithIdC<imported15_BondProgramC>, ReadonlyNonEmptyArrayType<t.TupleC<[imported53_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported449_WithStatusCU<RatingWithRelatedContentC<imported12_ProgramRatingC>>>]>>]>>;
export type ProgramRatingsWithRelatedContent = ReadonlyArray<[imported203_WithId<imported15_BondProgram>, RNEA.ReadonlyNonEmptyArray<[imported26_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported224_WithStatusU<RatingWithRelatedContent<imported12_ProgramRating>>>]>]>;
export const programRatingsWithRelatedContentC: ProgramRatingsWithRelatedContentC = t.readonlyArray(t.tuple([imported203_withIdC(imported12_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported52_RatingAgencyCU, readonlyNonEmptyArrayC(imported448_WithStatusCU(ratingWithRelatedContentC(imported9_programRatingC)))]))])) satisfies t.Type<ProgramRatingsWithRelatedContent, unknown>;


export type BondRatingsC = t.ReadonlyArrayC<t.TupleC<[imported55_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported451_WithStatusCU<RatingWithRelatedContentC<imported89_HasManyLinkC<imported4_BondRatingC, imported2_RelatedRatingLinkC>>>>]>>;
export type BondRatings = ReadonlyArray<[imported27_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported225_WithStatusU<RatingWithRelatedContent<imported89_HasManyLink<imported4_BondRating, imported2_RelatedRatingLink>>>>]>;
export const bondRatingsC: BondRatingsC = t.readonlyArray(t.tuple([imported54_RatingAgencyCU, readonlyNonEmptyArrayC(imported450_WithStatusCU(ratingWithRelatedContentC(imported89_hasManyLinkC(imported2_bondRatingC, imported2_relatedRatingLinkC))))])) satisfies t.Type<BondRatings, unknown>;


export type RatingPostRelatedContentC = t.TypeC<{
  documents: imported7_PostRelatedAndAttachedC<imported19_RelatedDocumentLinkC, imported7_MediaUploadResponseWithCategoryC, imported19_SortOrderC>,
  analysts: imported32_PostRelatedC<imported3_RelatedAnalystLinkC>
}>;
export type RatingPostRelatedContent = {
  documents: imported7_PostRelatedAndAttached<imported19_RelatedDocumentLink, imported7_MediaUploadResponseWithCategory, imported19_SortOrder>,
  analysts: imported32_PostRelated<imported3_RelatedAnalystLink>
};
export const ratingPostRelatedContentC: RatingPostRelatedContentC = t.type({
  documents: imported7_postRelatedAndAttachedC(imported19_relatedDocumentLinkC, imported7_mediaUploadResponseWithCategoryC, imported19_sortOrderC),
  analysts: imported32_postRelatedC(imported3_relatedAnalystLinkC)
}) satisfies t.Type<RatingPostRelatedContent, unknown>;


export type AttachedRatingPostBodyC = t.TypeC<{
  rating: t.StringC,
  ratingAgencyId: t.NumberC,
  ratingDate: LocalDateC,
  ratingNotes: OptionFromNullableC<MarkdownC>,
  ratingOutlookId: t.NumberC,
  relatedContent: RatingPostRelatedContentC
}>;
export type AttachedRatingPostBody = {
  rating: string,
  ratingAgencyId: number,
  ratingDate: LocalDate,
  ratingNotes: O.Option<Markdown>,
  ratingOutlookId: number,
  relatedContent: RatingPostRelatedContent
};
export const attachedRatingPostBodyC: AttachedRatingPostBodyC = t.type({
  rating: t.string,
  ratingAgencyId: t.number,
  ratingDate: LocalDateC,
  ratingNotes: optionFromNullable(markdownC),
  ratingOutlookId: t.number,
  relatedContent: ratingPostRelatedContentC
}) satisfies t.Type<AttachedRatingPostBody, unknown>;


export type IssuerAndProgramRatingsByAgencyC = t.TypeC<{
  issuerRatings: t.ReadonlyArrayC<t.TupleC<[imported58_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported454_WithStatusCU<RatingWithRelatedContentC<imported13_IssuerRatingC>>>]>>,
  programRatings: t.ReadonlyArrayC<t.TupleC<[imported204_WithIdC<imported16_BondProgramC>, ReadonlyNonEmptyArrayType<t.TupleC<[imported59_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported455_WithStatusCU<RatingWithRelatedContentC<imported13_ProgramRatingC>>>]>>]>>
}>;
export type IssuerAndProgramRatingsByAgency = {
  issuerRatings: ReadonlyArray<[imported28_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported226_WithStatusU<RatingWithRelatedContent<imported13_IssuerRating>>>]>,
  programRatings: ReadonlyArray<[imported204_WithId<imported16_BondProgram>, RNEA.ReadonlyNonEmptyArray<[imported29_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported227_WithStatusU<RatingWithRelatedContent<imported13_ProgramRating>>>]>]>
};
export const issuerAndProgramRatingsByAgencyC: IssuerAndProgramRatingsByAgencyC = t.type({
  issuerRatings: t.readonlyArray(t.tuple([imported56_RatingAgencyCU, readonlyNonEmptyArrayC(imported452_WithStatusCU(ratingWithRelatedContentC(imported10_issuerRatingC)))])),
  programRatings: t.readonlyArray(t.tuple([imported204_withIdC(imported13_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported57_RatingAgencyCU, readonlyNonEmptyArrayC(imported453_WithStatusCU(ratingWithRelatedContentC(imported10_programRatingC)))]))]))
}) satisfies t.Type<IssuerAndProgramRatingsByAgency, unknown>;


export type IssuerRatingPostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  rating: t.StringC,
  ratingAgencyId: t.NumberC,
  ratingDate: LocalDateC,
  ratingNotes: OptionFromNullableC<MarkdownC>,
  ratingOutlookId: t.NumberC,
  relatedContent: RatingPostRelatedContentC
}>;
export type IssuerRatingPostBody = {
  id: O.Option<number>,
  rating: string,
  ratingAgencyId: number,
  ratingDate: LocalDate,
  ratingNotes: O.Option<Markdown>,
  ratingOutlookId: number,
  relatedContent: RatingPostRelatedContent
};
export const issuerRatingPostBodyC: IssuerRatingPostBodyC = t.type({
  id: optionFromNullable(t.number),
  rating: t.string,
  ratingAgencyId: t.number,
  ratingDate: LocalDateC,
  ratingNotes: optionFromNullable(markdownC),
  ratingOutlookId: t.number,
  relatedContent: ratingPostRelatedContentC
}) satisfies t.Type<IssuerRatingPostBody, unknown>;


export type ProgramRatingPostBodyC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  bondProgramId: t.NumberC,
  rating: t.StringC,
  ratingAgencyId: t.NumberC,
  ratingDate: LocalDateC,
  ratingNotes: OptionFromNullableC<MarkdownC>,
  ratingOutlookId: t.NumberC,
  relatedContent: RatingPostRelatedContentC
}>;
export type ProgramRatingPostBody = {
  id: O.Option<number>,
  bondProgramId: number,
  rating: string,
  ratingAgencyId: number,
  ratingDate: LocalDate,
  ratingNotes: O.Option<Markdown>,
  ratingOutlookId: number,
  relatedContent: RatingPostRelatedContent
};
export const programRatingPostBodyC: ProgramRatingPostBodyC = t.type({
  id: optionFromNullable(t.number),
  bondProgramId: t.number,
  rating: t.string,
  ratingAgencyId: t.number,
  ratingDate: LocalDateC,
  ratingNotes: optionFromNullable(markdownC),
  ratingOutlookId: t.number,
  relatedContent: ratingPostRelatedContentC
}) satisfies t.Type<ProgramRatingPostBody, unknown>;


