import { WithStatusCU as imported463_WithStatusCU, WithId as imported210_WithId, WithStatusCU as imported471_WithStatusCU, WithStatusCU as imported465_WithStatusCU, withIdC as imported211_withIdC, WithStatusU as imported231_WithStatusU, WithStatusCU as imported467_WithStatusCU, WithStatusCU as imported469_WithStatusCU, WithStatusU as imported235_WithStatusU, WithStatusCU as imported470_WithStatusCU, WithStatusCU as imported462_WithStatusCU, WithStatusCU as imported472_WithStatusCU, WithStatusU as imported236_WithStatusU, withIdC as imported210_withIdC, WithStatusU as imported233_WithStatusU, WithStatusCU as imported473_WithStatusCU, WithIdC as imported210_WithIdC, WithStatusU as imported232_WithStatusU, WithIdC as imported211_WithIdC, WithId as imported211_WithId, WithStatusCU as imported464_WithStatusCU, WithStatusCU as imported468_WithStatusCU, WithStatusU as imported234_WithStatusU, WithStatusCU as imported466_WithStatusCU } from "./threadThrough";
import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { OfferingParticipantC as imported17_OfferingParticipantC, OfferingParticipant as imported17_OfferingParticipant, offeringParticipantC as imported15_offeringParticipantC } from "./offeringParticipant";
import { ProgramRating as imported15_ProgramRating, issuerRatingC as imported12_issuerRatingC, programRatingC as imported12_programRatingC, IssuerRating as imported15_IssuerRating, ProgramRatingC as imported15_ProgramRatingC, IssuerRatingC as imported15_IssuerRatingC } from "./ratingBase";
import { RatingAgencyCU as imported67_RatingAgencyCU, RatingAgencyCU as imported65_RatingAgencyCU, RatingAgencyU as imported33_RatingAgencyU, RatingAgencyU as imported32_RatingAgencyU, RatingAgencyCU as imported66_RatingAgencyCU, RatingAgencyCU as imported64_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { ratingWithRelatedContentC as imported20_ratingWithRelatedContentC, RatingWithRelatedContentC as imported20_RatingWithRelatedContentC, RatingWithRelatedContent as imported19_RatingWithRelatedContent, ratingWithRelatedContentC as imported19_ratingWithRelatedContentC, RatingWithRelatedContentC as imported19_RatingWithRelatedContentC, RatingWithRelatedContent as imported20_RatingWithRelatedContent } from "./rating";
import { TaggedContent as imported125_TaggedContent, TaggedContent as imported124_TaggedContent, TaggedContentC as imported125_TaggedContentC, TaggedContentC as imported126_TaggedContentC, taggedContentC as imported125_taggedContentC, TaggedContent as imported126_TaggedContent, taggedContentC as imported124_taggedContentC, taggedContentC as imported126_taggedContentC, TaggedContentC as imported124_TaggedContentC } from "./taggedContent";
import * as O from "fp-ts/lib/Option";
import { BondProgramC as imported19_BondProgramC, BondProgram as imported19_BondProgram, bondProgramC as imported15_bondProgramC } from "./bondProgramBase";
import { BondProgramWithRelatedContentC as imported3_BondProgramWithRelatedContentC, BondProgramWithRelatedContent as imported3_BondProgramWithRelatedContent, bondProgramWithRelatedContentC as imported3_bondProgramWithRelatedContentC } from "./bondProgram";
import { subscribedC as imported23_subscribedC, SubscribedC as imported23_SubscribedC, Subscribed as imported23_Subscribed, subscribedC as imported24_subscribedC, SubscribedC as imported24_SubscribedC, Subscribed as imported24_Subscribed } from "./subscribed";
import { CustomPageDataOC as imported4_CustomPageDataOC, CustomPageDataO as imported4_CustomPageDataO, customPageDataOC as imported4_customPageDataOC } from "./customPages";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { RoadShowDataC as imported19_RoadShowDataC, RoadShowData as imported19_RoadShowData, roadShowDataC as imported19_roadShowDataC } from "./roadshow";
import { BondOfferingWithRelatedContent as imported10_BondOfferingWithRelatedContent, BondOfferingWithRelatedContentC as imported10_BondOfferingWithRelatedContentC, bondOfferingWithRelatedContentC as imported9_bondOfferingWithRelatedContentC, BondOfferingWithRelatedContentC as imported9_BondOfferingWithRelatedContentC, BondOfferingWithRelatedContent as imported9_BondOfferingWithRelatedContent, bondOfferingWithRelatedContentC as imported10_bondOfferingWithRelatedContentC } from "./bondOffering";
import { ClientTextItemC as imported19_ClientTextItemC, clientTextItemC as imported19_clientTextItemC, clientTextItemC as imported18_clientTextItemC, ClientTextItem as imported19_ClientTextItem, ClientTextItemC as imported18_ClientTextItemC, ClientTextItem as imported18_ClientTextItem } from "./clientTextItem";

export type BondsSsrDataC = t.TypeC<{
  offerings: t.ReadonlyArrayC<imported23_SubscribedC<imported467_WithStatusCU<imported124_TaggedContentC<imported9_BondOfferingWithRelatedContentC>>>>,
  roadshows: t.ReadonlyArrayC<imported210_WithIdC<imported125_TaggedContentC<imported19_RoadShowDataC>>>,
  participants: t.ReadonlyArrayC<imported468_WithStatusCU<imported17_OfferingParticipantC>>,
  generalInfo: OptionFromNullableC<imported18_ClientTextItemC>,
  issuerRatings: t.ReadonlyArrayC<t.TupleC<[imported66_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported469_WithStatusCU<imported19_RatingWithRelatedContentC<imported15_IssuerRatingC>>>]>>,
  programRatings: t.ReadonlyArrayC<t.TupleC<[imported211_WithIdC<imported19_BondProgramC>, ReadonlyNonEmptyArrayType<t.TupleC<[imported67_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported470_WithStatusCU<imported20_RatingWithRelatedContentC<imported15_ProgramRatingC>>>]>>]>>,
  programs: t.ReadonlyArrayC<imported471_WithStatusCU<imported3_BondProgramWithRelatedContentC>>,
  customPages: t.ReadonlyArrayC<imported4_CustomPageDataOC>
}>;
export type BondsSsrData = {
  offerings: ReadonlyArray<imported23_Subscribed<imported231_WithStatusU<imported124_TaggedContent<imported9_BondOfferingWithRelatedContent>>>>,
  roadshows: ReadonlyArray<imported210_WithId<imported125_TaggedContent<imported19_RoadShowData>>>,
  participants: ReadonlyArray<imported232_WithStatusU<imported17_OfferingParticipant>>,
  generalInfo: O.Option<imported18_ClientTextItem>,
  issuerRatings: ReadonlyArray<[imported32_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported233_WithStatusU<imported19_RatingWithRelatedContent<imported15_IssuerRating>>>]>,
  programRatings: ReadonlyArray<[imported211_WithId<imported19_BondProgram>, RNEA.ReadonlyNonEmptyArray<[imported33_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported234_WithStatusU<imported20_RatingWithRelatedContent<imported15_ProgramRating>>>]>]>,
  programs: ReadonlyArray<imported235_WithStatusU<imported3_BondProgramWithRelatedContent>>,
  customPages: ReadonlyArray<imported4_CustomPageDataO>
};
export const bondsSsrDataC: BondsSsrDataC = t.type({
  offerings: t.readonlyArray(imported23_subscribedC(imported462_WithStatusCU(imported124_taggedContentC(imported9_bondOfferingWithRelatedContentC)))),
  roadshows: t.readonlyArray(imported210_withIdC(imported125_taggedContentC(imported19_roadShowDataC))),
  participants: t.readonlyArray(imported463_WithStatusCU(imported15_offeringParticipantC)),
  generalInfo: optionFromNullable(imported18_clientTextItemC),
  issuerRatings: t.readonlyArray(t.tuple([imported64_RatingAgencyCU, readonlyNonEmptyArrayC(imported464_WithStatusCU(imported19_ratingWithRelatedContentC(imported12_issuerRatingC)))])),
  programRatings: t.readonlyArray(t.tuple([imported211_withIdC(imported15_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported65_RatingAgencyCU, readonlyNonEmptyArrayC(imported465_WithStatusCU(imported20_ratingWithRelatedContentC(imported12_programRatingC)))]))])),
  programs: t.readonlyArray(imported466_WithStatusCU(imported3_bondProgramWithRelatedContentC)),
  customPages: t.readonlyArray(imported4_customPageDataOC)
}) satisfies t.Type<BondsSsrData, unknown>;


export type ArchivedBondsSsrDataC = t.TypeC<{
  offerings: t.ReadonlyArrayC<imported24_SubscribedC<imported473_WithStatusCU<imported126_TaggedContentC<imported10_BondOfferingWithRelatedContentC>>>>,
  generalInfo: OptionFromNullableC<imported19_ClientTextItemC>
}>;
export type ArchivedBondsSsrData = {
  offerings: ReadonlyArray<imported24_Subscribed<imported236_WithStatusU<imported126_TaggedContent<imported10_BondOfferingWithRelatedContent>>>>,
  generalInfo: O.Option<imported19_ClientTextItem>
};
export const archivedBondsSsrDataC: ArchivedBondsSsrDataC = t.type({
  offerings: t.readonlyArray(imported24_subscribedC(imported472_WithStatusCU(imported126_taggedContentC(imported10_bondOfferingWithRelatedContentC)))),
  generalInfo: optionFromNullable(imported19_clientTextItemC)
}) satisfies t.Type<ArchivedBondsSsrData, unknown>;


