import { IssuerC as imported46_IssuerC, Issuer as imported46_Issuer, issuerC as imported45_issuerC } from "./issuer";
import { RelativeUrlC, RelativeUrl, relativeUrlC } from "../../codecs/relativeUrl";
import * as t from "io-ts";

export type PrivateSiteC = t.TypeC<{
  issuer: imported46_IssuerC,
  redirect: RelativeUrlC
}>;
export type PrivateSite = {
  issuer: imported46_Issuer,
  redirect: RelativeUrl
};
export const privateSiteC: PrivateSiteC = t.type({
  issuer: imported45_issuerC,
  redirect: relativeUrlC
}) satisfies t.Type<PrivateSite, unknown>;


export type ConfirmSitePasswordC = t.TypeC<{
  id: t.NumberC,
  publishPassword: t.StringC,
  redirect: RelativeUrlC
}>;
export type ConfirmSitePassword = {
  id: number,
  publishPassword: string,
  redirect: RelativeUrl
};
export const confirmSitePasswordC: ConfirmSitePasswordC = t.type({
  id: t.number,
  publishPassword: t.string,
  redirect: relativeUrlC
}) satisfies t.Type<ConfirmSitePassword, unknown>;


