import { IssuerC as imported47_IssuerC, Issuer as imported47_Issuer, issuerC as imported46_issuerC } from "./issuer";
import * as t from "io-ts";
import { AddressC as imported7_AddressC, Address as imported7_Address, addressC as imported6_addressC } from "./address";
import { BondOfferingC as imported21_BondOfferingC, BondOffering as imported20_BondOffering, BondOffering as imported21_BondOffering, bondOfferingC as imported20_bondOfferingC, bondOfferingC as imported21_bondOfferingC, BondOfferingC as imported20_BondOfferingC } from "./bondOfferingBase";
import { RfpC as imported56_RfpC, Rfp as imported56_Rfp, rfpC as imported53_rfpC, RfpC as imported55_RfpC, rfpC as imported54_rfpC, Rfp as imported55_Rfp } from "./rfpBase";

export type IssuerWithDealsC = t.TypeC<{
  issuer: imported47_IssuerC,
  address: imported7_AddressC,
  activeOfferings: t.ReadonlyArrayC<imported20_BondOfferingC>,
  archivedOfferings: t.ReadonlyArrayC<imported21_BondOfferingC>,
  activeRfps: t.ReadonlyArrayC<imported55_RfpC>,
  archivedRfps: t.ReadonlyArrayC<imported56_RfpC>
}>;
export type IssuerWithDeals = {
  issuer: imported47_Issuer,
  address: imported7_Address,
  activeOfferings: ReadonlyArray<imported20_BondOffering>,
  archivedOfferings: ReadonlyArray<imported21_BondOffering>,
  activeRfps: ReadonlyArray<imported55_Rfp>,
  archivedRfps: ReadonlyArray<imported56_Rfp>
};
export const issuerWithDealsC: IssuerWithDealsC = t.type({
  issuer: imported46_issuerC,
  address: imported6_addressC,
  activeOfferings: t.readonlyArray(imported20_bondOfferingC),
  archivedOfferings: t.readonlyArray(imported21_bondOfferingC),
  activeRfps: t.readonlyArray(imported53_rfpC),
  archivedRfps: t.readonlyArray(imported54_rfpC)
}) satisfies t.Type<IssuerWithDeals, unknown>;


