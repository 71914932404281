import { withIdC as imported215_withIdC, WithId as imported215_WithId, WithStatusCU as imported519_WithStatusCU, withIdC as imported216_withIdC, WithIdC as imported215_WithIdC, WithStatusU as imported259_WithStatusU, WithStatusCU as imported518_WithStatusCU, WithId as imported216_WithId, WithIdC as imported216_WithIdC } from "./threadThrough";
import * as t from "io-ts";
import * as O from "fp-ts/lib/Option";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { MarkdownC, Markdown, markdownC } from "../../codecs/markdown";

export type AttachedFaqC = t.TypeC<{
  question: t.StringC,
  answer: MarkdownC
}>;
export type AttachedFaq = {
  question: string,
  answer: Markdown
};
export const attachedFaqC: AttachedFaqC = t.type({
  question: t.string,
  answer: markdownC
}) satisfies t.Type<AttachedFaq, unknown>;


export type FaqSectionC = t.TypeC<{
  name: t.StringC,
  sortOrder: t.NumberC
}>;
export type FaqSection = {
  name: string,
  sortOrder: number
};
export const faqSectionC: FaqSectionC = t.type({
  name: t.string,
  sortOrder: t.number
}) satisfies t.Type<FaqSection, unknown>;


export type FaqC = t.TypeC<{
  question: t.StringC,
  answer: MarkdownC,
  faqSection: imported215_WithIdC<FaqSectionC>,
  faqOrder: t.NumberC
}>;
export type Faq = {
  question: string,
  answer: Markdown,
  faqSection: imported215_WithId<FaqSection>,
  faqOrder: number
};
export const faqC: FaqC = t.type({
  question: t.string,
  answer: markdownC,
  faqSection: imported215_withIdC(faqSectionC),
  faqOrder: t.number
}) satisfies t.Type<Faq, unknown>;


export type FaqPostC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  question: t.StringC,
  answer: MarkdownC,
  sectionId: t.NumberC,
  faqOrder: t.NumberC
}>;
export type FaqPost = {
  id: O.Option<number>,
  question: string,
  answer: Markdown,
  sectionId: number,
  faqOrder: number
};
export const faqPostC: FaqPostC = t.type({
  id: optionFromNullable(t.number),
  question: t.string,
  answer: markdownC,
  sectionId: t.number,
  faqOrder: t.number
}) satisfies t.Type<FaqPost, unknown>;


export type FaqSectionPostC = t.TypeC<{
  id: OptionFromNullableC<t.NumberC>,
  name: t.StringC,
  sortOrder: t.NumberC
}>;
export type FaqSectionPost = {
  id: O.Option<number>,
  name: string,
  sortOrder: number
};
export const faqSectionPostC: FaqSectionPostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  sortOrder: t.number
}) satisfies t.Type<FaqSectionPost, unknown>;


export type IssuerFaqsWithSectionsC = t.TypeC<{
  faqs: t.ReadonlyArrayC<t.TupleC<[imported216_WithIdC<FaqSectionC>, t.ReadonlyArrayC<imported519_WithStatusCU<FaqC>>]>>
}>;
export type IssuerFaqsWithSections = {
  faqs: ReadonlyArray<[imported216_WithId<FaqSection>, ReadonlyArray<imported259_WithStatusU<Faq>>]>
};
export const issuerFaqsWithSectionsC: IssuerFaqsWithSectionsC = t.type({
  faqs: t.readonlyArray(t.tuple([imported216_withIdC(faqSectionC), t.readonlyArray(imported518_WithStatusCU(faqC))]))
}) satisfies t.Type<IssuerFaqsWithSections, unknown>;


