import { ReadonlyNonEmptyArrayType, readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { issuerRatingC as imported11_issuerRatingC, programRatingC as imported11_programRatingC, ProgramRating as imported14_ProgramRating, IssuerRating as imported14_IssuerRating, IssuerRatingC as imported14_IssuerRatingC, ProgramRatingC as imported14_ProgramRatingC } from "./ratingBase";
import { RatingAgencyCU as imported61_RatingAgencyCU, RatingAgencyU as imported30_RatingAgencyU, RatingAgencyCU as imported63_RatingAgencyCU, RatingAgencyU as imported31_RatingAgencyU, RatingAgencyCU as imported60_RatingAgencyCU, RatingAgencyCU as imported62_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { MediaC as imported51_MediaC, Media as imported51_Media, mediaC as imported48_mediaC } from "./media";
import * as O from "fp-ts/lib/Option";
import { BondProgramC as imported18_BondProgramC, BondProgram as imported18_BondProgram, bondProgramC as imported14_bondProgramC } from "./bondProgramBase";
import { OptionFromNullableC, optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { WithStatusCU as imported459_WithStatusCU, WithStatusU as imported230_WithStatusU, WithStatusU as imported229_WithStatusU, WithStatusCU as imported458_WithStatusCU, WithStatusCU as imported460_WithStatusCU, WithStatusCU as imported461_WithStatusCU, withIdC as imported209_withIdC, WithIdC as imported209_WithIdC, WithId as imported209_WithId } from "./threadThrough";
import { IssuerC as imported48_IssuerC, Issuer as imported48_Issuer, issuerC as imported47_issuerC } from "./issuer";

export type IssuerOverviewCardC = t.TypeC<{
  issuer: imported48_IssuerC,
  offerings: t.NumberC,
  issuerRatings: t.ReadonlyArrayC<t.TupleC<[imported62_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported460_WithStatusCU<imported14_IssuerRatingC>>]>>,
  programRatings: t.ReadonlyArrayC<t.TupleC<[imported209_WithIdC<imported18_BondProgramC>, ReadonlyNonEmptyArrayType<t.TupleC<[imported63_RatingAgencyCU, ReadonlyNonEmptyArrayType<imported461_WithStatusCU<imported14_ProgramRatingC>>]>>]>>,
  subscribed: t.BooleanC,
  media: OptionFromNullableC<imported51_MediaC>,
  outstandingDebt: OptionFromNullableC<t.NumberC>
}>;
export type IssuerOverviewCard = {
  issuer: imported48_Issuer,
  offerings: number,
  issuerRatings: ReadonlyArray<[imported30_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported229_WithStatusU<imported14_IssuerRating>>]>,
  programRatings: ReadonlyArray<[imported209_WithId<imported18_BondProgram>, RNEA.ReadonlyNonEmptyArray<[imported31_RatingAgencyU, RNEA.ReadonlyNonEmptyArray<imported230_WithStatusU<imported14_ProgramRating>>]>]>,
  subscribed: boolean,
  media: O.Option<imported51_Media>,
  outstandingDebt: O.Option<number>
};
export const issuerOverviewCardC: IssuerOverviewCardC = t.type({
  issuer: imported47_issuerC,
  offerings: t.number,
  issuerRatings: t.readonlyArray(t.tuple([imported60_RatingAgencyCU, readonlyNonEmptyArrayC(imported458_WithStatusCU(imported11_issuerRatingC))])),
  programRatings: t.readonlyArray(t.tuple([imported209_withIdC(imported14_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported61_RatingAgencyCU, readonlyNonEmptyArrayC(imported459_WithStatusCU(imported11_programRatingC))]))])),
  subscribed: t.boolean,
  media: optionFromNullable(imported48_mediaC),
  outstandingDebt: optionFromNullable(t.number)
}) satisfies t.Type<IssuerOverviewCard, unknown>;


export type IssuerOverviewCardsC = t.TypeC<{
  cards: t.ReadonlyArrayC<IssuerOverviewCardC>,
  nextPage: t.BooleanC
}>;
export type IssuerOverviewCards = {
  cards: ReadonlyArray<IssuerOverviewCard>,
  nextPage: boolean
};
export const issuerOverviewCardsC: IssuerOverviewCardsC = t.type({
  cards: t.readonlyArray(issuerOverviewCardC),
  nextPage: t.boolean
}) satisfies t.Type<IssuerOverviewCards, unknown>;


